import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ToastContainer, Slide } from 'react-toastify';
import {
  useParams,
  Outlet,
  useNavigate,
  useSearchParams
} from 'react-router-dom';
import { Layout, message } from 'antd';
import { useMixpanel } from 'react-mixpanel-browser';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import {
  getAssetItem,
  setAssetVariant,
  getAssetInternalComments,
  createNewComment,
  removeComment,
  editComment,
  setCommentLike,
  getAssetFolder
} from '@redux/actions/mediaViewerAction';
import { setCampaign } from '@redux/actions/campaignAction';
import { useCurrentWorkspace } from '@hooks/workspace';

import MediaViewerHeader from '@components/MediaViewerHeader';
import WorkspacesPanel from '@components/WorkspacesPanel';
import { MediaViewerActionTypes } from '@redux/types/mediaViewerType';
import {
  AssetVersionCommentMentionDto,
  CreateAssetVersionCommentDto,
  PageDto,
  ReplyAssetVersionCommentDto,
  SetAssetVersionCommentLikeDto,
  UpdateAssetVersionCommentDto
} from '@api/Api';
import {
  assetInternalCommentsCreateAssetComment,
  assetInternalCommentsDeleteAssetComment,
  assetInternalCommentsGetAssetComment,
  assetInternalCommentsGetAssetComments,
  assetInternalCommentsListCommentPotentialMentions,
  assetInternalCommentsReplyAssetComment,
  assetInternalCommentsSetAssetCommentLike,
  assetInternalCommentsUpdateAssetComment,
  assetSetRecentlyOpened
} from '@api/Asset';
import { useTypedSelector } from '@hooks';
import {
  useWebSocketDebouncedSubscription,
  useWebSocketSubscription
} from '@hooks/useWebSocketClient';
import { MixpanelService, MixpanelEventType } from '@services/mixpanelService';
import { useAuth } from '@hooks/useAuth';

import { ReactComponent as SuccessSvg } from '@assets/icons/success.svg';
import { ReactComponent as ErrorSvg } from '@assets/icons/error.svg';
import { toggleApprovalModal } from '@redux/actions/modalAction';
import { OnboardingProcessContext } from '@context/OnboardingProcessProvider';
import { apiClient } from '@api/client';

const { Header, Content, Sider } = Layout;

function MediaViewerLayout() {
  const { onStartTour } = useContext(OnboardingProcessContext);
  const { assetId, variantId } = useParams();
  const [isDeleted, setIsDeleted] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();
  const [searchParams] = useSearchParams();
  const { user } = useAuth(false, 'internal');
  const [currentWorkspace, setCurrentWorkspace] = useCurrentWorkspace(false);
  const modalState = useTypedSelector(({ modal }) => modal.approvalModal);
  const workspacesListSiderWidth = 65;
  const assets = useTypedSelector(({ mediaViewer }) => mediaViewer.assets);
  const selectedVersion = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.selectedVersion
  );
  const searchParamsRef = useRef(searchParams);
  searchParamsRef.current = searchParams;
  const assetVersionId = selectedVersion?.id;

  useEffect(() => {
    if (!assets) return;
    const versionExists = assets.asset?.versions.find(
      (it) => it.id === variantId
    );
    if (versionExists) return;
    if (!variantId) {
      navigate(`/media-viewer/${assets.id}/${assets.asset?.versions[0].id}`);
      return;
    }
    if (selectedVersion?.id === variantId) return;
    const folderId = assets.path[0]?.id;
    const { campaignId } = assets;
    if (folderId) {
      navigate(`/campaigns/single/${campaignId}/${folderId}`);
      return;
    }
    navigate(`/campaigns/single/${campaignId}`);
  }, [navigate, variantId, assets, selectedVersion]);

  useEffect(() => {
    if (!assetId || !assetVersionId) return;
    (async () => {
      await assetSetRecentlyOpened({ assetId, assetVersionId });
    })();
  }, [assetId, assetVersionId]);

  const outletContext = useMemo(
    () => ({
      addNewComment: async (
        data: CreateAssetVersionCommentDto | ReplyAssetVersionCommentDto,
        parentId: string
      ) => {
        try {
          let newComment = null;
          if (parentId && parentId !== 'new') {
            const {
              data: { comment }
            } = await assetInternalCommentsReplyAssetComment(
              data as ReplyAssetVersionCommentDto
            );
            newComment = comment;
          } else {
            const {
              data: { comment }
            } = await assetInternalCommentsCreateAssetComment(
              data as CreateAssetVersionCommentDto
            );
            newComment = comment;
            if (user) {
              await MixpanelService.track(
                mixpanel,
                user.id,
                MixpanelEventType.MANAGE_ASSETS,
                {
                  addToFavorite: false,
                  deleteAsset: false,
                  newAsset: false,
                  externalLinkShare: false,
                  approvalRouting: false,
                  newCommentAsset: true,
                  annotationLeft: !!comment.annotations,
                  taskFromComment: false
                }
              );
            }
          }
          dispatch(createNewComment(newComment));
          return newComment;
        } catch (error: any) {
          message.error({
            content: 'Something went wrong',
            className: 'message-dark-modal'
          });
          throw error;
        }
      },
      removeComment: async (id: string) => {
        try {
          await assetInternalCommentsDeleteAssetComment({ id });
          dispatch(removeComment(id));
        } catch (error: any) {
          message.error({
            content: 'Something went wrong',
            className: 'message-dark-modal'
          });
        }
      },
      editComment: async (data: UpdateAssetVersionCommentDto) => {
        try {
          const {
            data: { comment }
          } = await assetInternalCommentsUpdateAssetComment(data);
          dispatch(editComment(comment));
        } catch (error: any) {
          message.error({
            content: 'Something went wrong',
            className: 'message-dark-modal'
          });
        }
      },
      setCommentLike: async (
        data: SetAssetVersionCommentLikeDto,
        user: any
      ) => {
        try {
          await assetInternalCommentsSetAssetCommentLike(data);
          dispatch(setCommentLike(data, user));
        } catch (error: any) {
          message.error({
            content: 'Something went wrong',
            className: 'message-dark-modal'
          });
        }
      },
      getPotentialMentions: async ({
        assetVersionId,
        searchQuery
      }: {
        assetVersionId: string;
        searchQuery?: string;
      }): Promise<AssetVersionCommentMentionDto[]> => {
        const {
          data: { edges }
        }: {
          data: PageDto & { edges: { node: AssetVersionCommentMentionDto }[] };
        } = await assetInternalCommentsListCommentPotentialMentions({
          assetVersionId,
          searchQuery
        });
        if (edges) {
          const list = edges.map((item) => item.node);
          return list;
        }
        return [];
      },
      getPotentialGroupMentions: async ({
        assetVersionId,
        searchQuery
      }: {
        assetVersionId: string;
        searchQuery?: string;
      }): Promise<AssetVersionCommentMentionDto[]> => {
        const {
          data: { edges }
        }: {
          data: PageDto & { edges: { node: AssetVersionCommentMentionDto }[] };
        } =
          await apiClient.asset.assetInternalCommentsControllerListCommentPotentialGroupMentions(
            {
              assetVersionId,
              searchQuery
            }
          );
        if (edges) {
          const list = edges.map((item) => item.node);
          return list;
        }
        return [];
      },
      workspace: currentWorkspace
    }),
    [currentWorkspace, dispatch, mixpanel, user]
  );

  const getCommentsList = async (assetVersionId: string) => {
    try {
      const {
        data: { comments }
      } = await assetInternalCommentsGetAssetComments({ assetVersionId });
      dispatch(getAssetInternalComments(comments));
    } catch (error: any) {
      message.error({
        content: 'Something went wrong',
        className: 'message-dark-modal'
      });
    }
  };

  useEffect(() => {
    if (assetId) {
      (async () => {
        try {
          const asset = await getAssetItem({
            assetId
          })(dispatch);
          const folderId = asset.path?.[0]?.id;
          if (variantId) {
            dispatch(setAssetVariant(variantId));
            getCommentsList(variantId);
          }
          if (asset.workspaceId) {
            await dispatch(setCampaign(asset.workspaceId, {}, 'campaignList'));
            setCurrentWorkspace(asset.workspaceId);
          }
          if (folderId) {
            await getAssetFolder({
              assetId: folderId,
              isFolder: true
            })(dispatch);
          }
          const startTour =
            searchParamsRef.current.get('startTour') ?? undefined;
          if (startTour === 'route-for-approval') {
            if (!asset.asset) return;
            onStartTour({ type: startTour as any, theme: 'dark' });
          }
        } catch (error: any) {
          console.warn(error, '-> get asset');
          setIsDeleted(true);
        }
      })();
    }
    return () => {
      dispatch(toggleApprovalModal(null));
      dispatch({
        type: MediaViewerActionTypes.SET_RESET_ASSET_STATE
      });
    };
  }, [assetId]);

  useEffect(() => {
    if (variantId && currentWorkspace && selectedVersion) {
      return () => {
        dispatch({
          type: MediaViewerActionTypes.SET_RESET_ASSET_VERSION_STATE
        });
      };
    }
  }, [variantId, currentWorkspace, selectedVersion?.id]);

  useWebSocketSubscription(async (msg) => {
    if (msg.payload.assetId !== assetId) return;
    if (msg.payload.assetVersionId !== variantId) return;
    if (msg.causedByMe) return;
    const {
      data: { comment }
    } = await assetInternalCommentsGetAssetComment({
      id: msg.payload.commentId
    });
    dispatch(createNewComment(comment));
  }, 'asset-comment-created');

  useWebSocketSubscription(async (msg) => {
    if (msg.payload.assetId !== assetId) return;
    if (msg.payload.assetVersionId !== variantId) return;
    if (msg.causedByMe) return;

    dispatch(removeComment(msg.payload.commentId));
  }, 'asset-comment-removed');

  useWebSocketDebouncedSubscription(
    (msg) => msg.payload.assetId,
    async (messages) => {
      const lastMessage = messages[messages.length - 1];

      const {
        data: { comment }
      } = await assetInternalCommentsGetAssetComment({
        id: lastMessage.payload.commentId
      });
      dispatch({
        type: MediaViewerActionTypes.UPDATE_ASSET_INTERNAL_COMMENT,
        payload: comment
      });
    },
    1000,
    (msg) => {
      if (msg.payload.assetId !== assetId) return false;
      if (msg.payload.assetVersionId !== variantId) return false;
      if (msg.causedByMe) return false;
      return true;
    },
    'asset-comment-updated'
  );

  useWebSocketDebouncedSubscription(
    (msg) => msg.payload.assetId,
    async (messages) => {
      const lastMessage = messages[messages.length - 1];

      const newAsset = await getAssetItem({
        assetId: lastMessage.payload.assetId
      })(dispatch);
      if (
        lastMessage.causedByMe &&
        lastMessage.payload.reason === 'new-version'
      ) {
        const lastVersionId = newAsset?.asset?.versions[0].id;
        navigate(
          `/media-viewer/${lastMessage.payload.assetId}/${lastVersionId}`
        );
        message.success({
          content: `New version was uploaded ${moment(new Date()).format(
            'MMM DD, YYYY'
          )}`,
          className: 'message-dark-modal',
          icon: <SuccessSvg className="anticon" />
        });
        return;
      }

      if (variantId && lastMessage.payload.assetVersionId === variantId) {
        dispatch(setAssetVariant(variantId));
      }
      if (assets && modalState && modalState.visible) {
        const assetVersion = newAsset.asset?.versions.find(
          (x) => x.id === variantId
        );
        if (!assetVersion)
          dispatch(toggleApprovalModal({ ...modalState, visible: false }));
        else if (!modalState.isEditMode) {
          dispatch(
            toggleApprovalModal({
              ...modalState,
              assetVersion,
              isLastVersion:
                assetVersion.versionNumber === newAsset.asset?.versions.length
            })
          );
        }
      }
    },
    1000,
    (msg) => {
      if (msg.payload.assetId !== assetId) return false;
      if (
        msg.causedByMe &&
        msg.payload.reason !== 'conversion-status-changed' &&
        msg.payload.reason !== 'new-version'
      )
        return false;
      return true;
    },
    'asset-updated'
  );

  useEffect(() => {
    if (variantId && assets) {
      dispatch(setAssetVariant(variantId));
      getCommentsList(variantId);
    }
  }, [variantId]);

  return (
    <Layout
      style={{ minHeight: '100vh', background: '#1A1C1E', color: '#fff' }}
    >
      <Sider
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0
        }}
        className="workspaces_sider b-bg-dark"
        width={workspacesListSiderWidth}
      >
        <WorkspacesPanel />
      </Sider>
      <Layout
        className="site-layout"
        style={{ background: '#1A1C1E', marginLeft: workspacesListSiderWidth }}
      >
        {!isDeleted && (
          <Header
            className="site-layout-background b-background-black"
            style={{
              width: `calc(100% - ${workspacesListSiderWidth}px)`,
              padding: 0
            }}
          >
            <MediaViewerHeader />
          </Header>
        )}

        <Content style={{ marginTop: 70 }}>
          <div style={{ minHeight: 360, height: '100%' }}>
            {isDeleted ? (
              <div className="media_viewer_content_deleted">
                <ErrorSvg />
                <h3>Oops…looks like that media was deleted.</h3>
              </div>
            ) : (
              <Outlet context={outletContext} />
            )}
          </div>
        </Content>
      </Layout>
      <ToastContainer
        transition={Slide}
        closeOnClick={false}
        closeButton={false}
        position="top-center"
        theme="dark"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        progressClassName="fancy-progress-bar"
      />
    </Layout>
  );
}

export default MediaViewerLayout;
